"use client";

import { useEffect } from 'react';
import { AntdRegistry } from '@ant-design/nextjs-registry';
import { SellerProvider } from "@context/sellerContext";
import { LoadingProvider } from "@context/loadingContext"

export const Providers = ({ children }) => {
  useEffect(() => {
    const {search} = window.location;
    const urlParams = new URLSearchParams(search);
    const referral = urlParams.get('referral');
    if(referral && referral !== 'null') {
      localStorage.setItem('referral', referral);
    }
  }, [])
  return (
    <AntdRegistry>
      <LoadingProvider>
        <SellerProvider>
          {children}
        </SellerProvider>
      </LoadingProvider>
    </AntdRegistry>
  );
}
